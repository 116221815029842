import * as React from "react";
import Container from "@cloudscape-design/components/container";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import {Alert, Form, FormField, Input,} from "@cloudscape-design/components";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {postRedeemBundles} from "../../redux/storage/userStorage";
import CheckStatusAndSubmit from "../../components/check-status-and-submit";

export default function () {

    const secrets = useSelector(state => state.secrets)
    const site = useSelector(state => state.site)

    const [showLoadingView, setShowLoadingView] = useState(false)
    const [loading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState('')
    const [inputValue, setInputValue] = useState('')

    const navigate = useNavigate();
    const dispatch = useDispatch()

    let handleVoucherSubmit = async () => {

        setHasError(null)

        if (inputValue.length < 6) {
            setHasError("Invalid Card Code")
            return
        }

        if (!secrets.chapID || secrets.chapID === "") {
            setHasError("Incorrect Authentication")
            return
        }

        let data = {
            CardCode: inputValue,
            extra: {mac: secrets.mac, ip: site.siteIP}
        }

        setIsLoading(true)
        let resp = await dispatch(postRedeemBundles({domain: site.domain, data}))
        setIsLoading(false)
        if (resp.payload.success) {
            setShowLoadingView(true)
        } else {
            setHasError(resp.payload.error)
        }

    }


    let PurchaseView = <ColumnLayout disableGutters={true}>
        <Container header={<Header variant="h2">Complete Purchase</Header>}>
            <ColumnLayout>

                <Alert type={`info`} header={`Redeem voucher code`}>
                    Your are about to redeem your voucher code to access internet. Please fill in the correct code.
                </Alert>

                <ColumnLayout columns={2}>
                    <FormField
                        description="Voucher code provided"
                        label="Voucher code">
                        <Input
                            disabled={loading}
                            value={inputValue}
                            type={`text`}
                            min={0}
                            onChange={event =>
                                setInputValue(event.detail.value)
                            }/>
                    </FormField>
                </ColumnLayout>

            </ColumnLayout>
        </Container>
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button formAction="none" variant="link" disabled={loading}
                            onClick={() => {
                                navigate(-1)
                            }}>
                        Cancel
                    </Button>
                    <Button variant="primary" loading={loading} onClick={handleVoucherSubmit}>Redeem</Button>
                </SpaceBetween>
            }
        >
        </Form>
    </ColumnLayout>

    return (
        <ColumnLayout columns={2}>
            {showLoadingView ? <CheckStatusAndSubmit/> : PurchaseView}
        </ColumnLayout>
    );
}
